import { Text, Heading, VStack, SimpleGrid, Stack } from '@chakra-ui/react';
import { ServiceAddon, ServiceAddonValues } from '@components/ServiceAddon';
import { FormProvider, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { CountryCodes } from '@types';
import { ExportAddOnCheckbox } from '@components/ExportAddOnCheckbox';
import { useBookingForm } from 'context/formContext/formContext';
import { AddonsFormValues } from 'context/formContext/addonsForm';
import { StickyFooter } from '..';
import { usePage } from 'context/pageContext/pageContext';
import dataLayerHelper, { FormStep } from 'utils/helpers/dataLayer';
import { Addons } from 'utils/constants/packageGmvMap';

export const ServiceAddonsCountries = [
  CountryCodes.BE,
  CountryCodes.IT,
  CountryCodes.NL,
  CountryCodes.PL,
  CountryCodes.CZ,
  CountryCodes.FR,
  CountryCodes.LU,
  CountryCodes.AT,
  CountryCodes.ES,
  CountryCodes.UK,
  CountryCodes.SE,
  CountryCodes.DK,
  CountryCodes.IE,
  CountryCodes.DE,
];

export const AddonsForm: FC = () => {
  const { addonsForm } = useBookingForm();
  const { goPrev, goNext } = usePage();
  const { t } = useTranslation(['form', 'addons']);

  const filings = [...(addonsForm.watch('filings') || [])];
  const registrations = [...(addonsForm.watch('registrations') || [])];

  /*
    Service addons are stored under in two arrays in the state. (filings & registrations)
    So it has to transform into the shape of ServiceAddonValues to pass into the ServiceAddon component
  */
  const [serviceAddons] = useState<ServiceAddonValues[]>(() =>
    ServiceAddonsCountries.map((countryCode) => ({
      countryCode,
      filing: filings.includes(countryCode),
      filingType: registrations.includes(countryCode) ? 'reg' : 'switch',
    })),
  );

  const exportAddons = t('addons:exportAddons.addons', { returnObjects: true });

  const { handleSubmit } = addonsForm;

  const serviceAddonChange = ({
    filing,
    filingType,
    countryCode,
  }: ServiceAddonValues) => {
    let filings = [...(addonsForm.watch('filings') || [])];
    let registrations = [...(addonsForm.watch('registrations') || [])];

    if (!filing) {
      filings = filings.filter((country) => country !== countryCode);
      registrations = registrations.filter((country) => country !== countryCode);
    }

    if (filing) {
      filings = Array.from(new Set([...filings, countryCode]));
    }

    if (filing && filingType === 'reg') {
      registrations = Array.from(new Set([...registrations, countryCode]));
    } else {
      registrations = registrations.filter((country) => country !== countryCode);
    }

    addonsForm.setValue('filings', filings);
    addonsForm.setValue('registrations', registrations);
  };

  const goNextPage = () => {
    dataLayerHelper.checkout(FormStep.Addons);
    goNext();
  };

  const isExportAddonChecked = (addonValue: string) =>
    Boolean(addonsForm.watch(addonValue as keyof AddonsFormValues));

  return (
    <FormProvider {...addonsForm}>
      <form>
        {/* Export Add-ons */}
        <Heading fontSize={'4xl'} mt={16} mb={8}>
          {t('addons:headline')}
        </Heading>
        <Stack
          direction={['column', 'row']}
          align={'center'}
          justify={'space-between'}
          mb={8}>
          <Heading fontSize={'2xl'}>{t('addons:exportAddons.subHeadline')}</Heading>
          <Stack align={'end'}>
            <Text fontSize={'2xl'} fontWeight={'semibold'}>
              {'99 €'}
            </Text>
            <Text fontSize={'lg'}> {t('addons:exportAddons.priceDetail')}</Text>
          </Stack>
        </Stack>
        <Text fontSize={'2xl'} mb={12}>
          {t('addons:exportAddons.text')}
        </Text>
        <VStack spacing={3} mb={32}>
          {exportAddons.map((addon) => (
            <Controller
              key={addon.value}
              name={addon.value as keyof AddonsFormValues}
              render={({ field: props }) => (
                <ExportAddOnCheckbox
                  key={addon.value}
                  title={addon.header}
                  desc={addon.text}
                  tooltip={addon.tooltip}
                  value={addon.value}
                  onChange={(value) => {
                    // disable datevPayments addon when datev is unselected
                    if (addon.value === Addons.datev && !value) {
                      addonsForm.setValue('datevPayments', false);
                    }

                    props.onChange(value);
                  }}
                  isChecked={isExportAddonChecked(addon.value)}
                />
              )}
            />
          ))}
        </VStack>

        {/* Service Addons */}
        <Stack
          direction={['column', 'row']}
          align={'center'}
          justify={'space-between'}
          mb={8}>
          <Heading fontSize={'2xl'}>{t('addons:serviceAddons.subHeadline')}</Heading>

          <Stack align={'end'}>
            <Text fontSize={'2xl'} fontWeight={'semibold'}>
              {'99 €'}
            </Text>
            <Text fontSize={'lg'}>{t('addons:serviceAddons.priceDetail.other')}</Text>
          </Stack>
        </Stack>
        <Text fontSize={'2xl'} mb={12}>
          <Trans
            t={t}
            i18nKey="addons:serviceAddons.text"
            components={{
              1: <a href="#addon-form-footnote-1"></a>,
              sup: <sup></sup>,
            }}
          />
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mb={12}>
          {serviceAddons.map((addon) => (
            <ServiceAddon
              key={addon.countryCode}
              value={addon}
              onChange={(value) => serviceAddonChange(value)}
            />
          ))}
        </SimpleGrid>

        <Text w={{ base: 'full', md: '60%' }} size="sm" id={'addon-form-footnote-1'}>
          <sup>1 </sup>
          {t('addons:serviceAddons.noteOne')}
        </Text>

        <Text
          w={{ base: 'full', md: '60%' }}
          size="sm"
          mt={4}
          mb={16}
          id={'addon-form-footnote-2'}>
          <sup>2 </sup>
          {t('addons:serviceAddons.noteTwo')}
        </Text>
      </form>
      <StickyFooter onBack={handleSubmit(goPrev)} onNext={handleSubmit(goNextPage)} />
    </FormProvider>
  );
};
