import * as yup from 'yup';

export interface AddonsFormValues {
  datev?: boolean;
  datevPayments?: boolean;
  invoice?: boolean;
  oss?: boolean;
  registrations?: string[];
  filings?: string[];
}

export const addonsFormDefaults: AddonsFormValues = {};

export const addonsFormSchema = () =>
  yup.object().shape({
    datev: yup.boolean(),
    datevPayments: yup.boolean(),
    invoice: yup.boolean(),
    oss: yup.boolean(),
    filings: yup.array().of(yup.string()),
    registrations: yup.array().of(yup.string()),
  });
