import { Box, Divider, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import {
  DatePickerField,
  InputField,
  RadioGroupField,
  SelectField,
} from '@components/forms';
import AddressField from '@components/forms/AddressField';
import { CountrySelect } from '@components/forms/CountrySelect';
import { Address, EntityType, Salutation } from '@types';
import { useBookingForm } from 'context/formContext/formContext';
import { aboutFormSchema, AboutFormValues } from 'context/formContext/aboutForm';
import { FC, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StickyFooter } from '@components/StickyFooter';
import { usePage } from 'context/pageContext/pageContext';
import dataLayerHelper, { FormStep } from 'utils/helpers/dataLayer';
import { validateVatID } from 'services/validateVatNo';

/**
 * Renders the "About You" - step 3 - view.
 * containing company, personal, billing and contact information
 */
export const AboutForm: FC = () => {
  const { t } = useTranslation(['about', 'form', 'others']);
  const { goPrev, goNext } = usePage();
  const [isLoading, setIsLoading] = useState(false);
  const { aboutForm } = useBookingForm();
  const euCountries = t('others:countries', {
    returnObjects: true,
  });
  const entityTypeOptions = [
    {
      label: t('about:entityType.options.corporation'),
      value: EntityType.Legal,
    },
    {
      label: t('about:entityType.options.hybrid'),
      value: EntityType.PartnershipComplementary,
    },
    {
      label: t('about:entityType.options.other'),
      value: EntityType.Partnership,
    },
    {
      label: t('about:entityType.options.sole'),
      value: EntityType.Natural,
    },
  ];
  const salutationOptions = [
    {
      label: t('about:personalInformation.options.mr'),
      value: Salutation.Mr,
    },
    {
      label: t('about:personalInformation.options.ms'),
      value: Salutation.Ms,
    },
    {
      label: t('about:personalInformation.options.none'),
      value: Salutation.None,
    },
  ];

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = aboutForm;

  //Show or hide the D.O.B field based on the selected company type
  const isEntityTypeSole = watch('entityType') === EntityType.Natural;

  const handleStreetSelected = async (address: Address) => {
    address.street && setValue('street', address.street, { shouldValidate: true });
    if (address.country && euCountries.hasOwnProperty(address.country)) {
      setValue('country', address.country, { shouldValidate: true });
    }
    address.city && setValue('city', address.city, { shouldValidate: true });
    address.area && setValue('area', address.area, { shouldValidate: true });
    address.zip && setValue('zip', address.zip, { shouldValidate: true });
    address.streetNo && setValue('streetNo', address.streetNo, { shouldValidate: true });
  };

  /**
   * Check if a field is required or not in the yup validation object.
   * use this function if you want to link the 'required' attribute of a field to its yup validation object.
   * @param fieldName the schema field to check (the name must be one of the defined keys in AboutFormValues)
   * @returns Whether the field is required or not
   */
  const isFieldRequired = (fieldName: keyof AboutFormValues): boolean => {
    return aboutFormSchema(t).fields[fieldName]?.defined()?.spec?.presence === 'required';
  };

  const goNextPage = async (data: AboutFormValues) => {
    setIsLoading(true);
    // Checks if a VAT ID is valid or not by calling VATchecker API and update the field errors
    // This check is made on submit because the custom error was not persistent allowing users to submit invalid IDs
    const isVatValid = await validateVatID(data.vatNo);

    if (!isVatValid) {
      setError(
        'vatNo',
        {
          type: 'custom',
          message: t('form:error.invalid.vatNo'),
        },
        { shouldFocus: true },
      );
      setIsLoading(false);
    } else {
      setValue('vatNo', data.vatNo?.toUpperCase());
      setValue('email', data.email?.toLowerCase());
      dataLayerHelper.checkout(FormStep.About);
      setIsLoading(false);
      goNext();
    }
  };

  const MIN_LEGAL_CLIENT_AGE = 18;
  const maxBirthdayDate = new Date();
  maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - MIN_LEGAL_CLIENT_AGE);
  const {
    onChange: onStreetChange,
    onBlur: onStreetBlur,
    name: streetInputName,
    ref: streetInputRef,
  } = register('street');

  return (
    <FormProvider {...aboutForm}>
      <form>
        <Flex flexDirection="column" alignItems="center">
          <Heading as="h1" my="64px" fontSize="44px" alignSelf="start">
            {t('about:headline')}
          </Heading>
          <Stack maxW={{ base: '100%', md: '60%' }} spacing="64px">
            {/* Company Information */}
            <Stack id="company-infos" spacing="24px">
              <Heading as="h2" fontSize="xl" mb="12px">
                {t('about:companyInformation.header')}
              </Heading>
              <InputField
                id="companyName"
                isRequired={isFieldRequired('companyName')}
                label={t('about:companyInformation.companyName.label')}
                placeholder={t('about:companyInformation.companyName.placeholder')}
                {...register('companyName')}
                error={errors.companyName}
                fontSize={'xl'}
              />
              <RadioGroupField
                id="entityType"
                name="entityType"
                isRequired={isFieldRequired('entityType')}
                label={t('about:entityType.header')}
                options={entityTypeOptions}
                direction={'column'}
                defaultValue={entityTypeOptions[0].value}
                error={errors.entityType}
                onChange={() =>
                  setValue('dateOfBirth', undefined, { shouldValidate: true })
                }
              />
            </Stack>

            <Divider borderWidth={'1px'} />

            {/* Personal Information */}
            <Stack id="personal-infos" spacing="24px">
              <Heading as="h2" fontSize="xl" mb="12px">
                {t('about:personalInformation.header')}
              </Heading>
              <SelectField
                id="salutation"
                isRequired={isFieldRequired('salutation')}
                label={t('about:personalInformation.salutation.label')}
                placeholder={t('about:personalInformation.salutation.placeholder')}
                options={salutationOptions}
                {...register('salutation')}
                error={errors.salutation}
              />
              <Stack direction={{ base: 'column', md: 'row' }} spacing="24px">
                <InputField
                  id="name"
                  isRequired={isFieldRequired('name')}
                  fontSize={'xl'}
                  label={t('about:personalInformation.name.label')}
                  placeholder={t('about:personalInformation.name.placeholder')}
                  {...register('name')}
                  error={errors.name}
                />
                <InputField
                  id="surname"
                  isRequired={isFieldRequired('surname')}
                  fontSize={'xl'}
                  label={t('about:personalInformation.surname.label')}
                  placeholder={t('about:personalInformation.surname.placeholder')}
                  {...register('surname')}
                  error={errors.surname}
                />
              </Stack>
              {isEntityTypeSole && (
                <DatePickerField
                  id="dateOfBirth"
                  isRequired={isFieldRequired('dateOfBirth')}
                  name="dateOfBirth"
                  label={t('about:personalInformation.dateOfBirth.label')}
                  placeholder={t('about:personalInformation.dateOfBirth.placeholder')}
                  error={errors.dateOfBirth}
                  maxDate={maxBirthdayDate}
                />
              )}
            </Stack>

            <Divider borderWidth={'1px'} />

            {/* Billing Information */}
            <Stack id="billing-infos" spacing="24px">
              <Heading as="h2" fontSize="xl" mb="12px">
                {t('about:billing.header')}
              </Heading>

              <Stack direction={['column', 'row']} spacing="24px">
                <Box w={['full', '368px']}>
                  <AddressField
                    id="street"
                    isRequired={isFieldRequired('street')}
                    label={t('about:billing.street.label')}
                    fontSize={'xl'}
                    placeholder={t('about:billing.street.placeholder')}
                    ref={streetInputRef}
                    name={streetInputName}
                    onBlur={onStreetBlur}
                    onChangeHandler={onStreetChange}
                    onSelectValue={handleStreetSelected}
                    error={errors.street}
                  />
                </Box>
                <Box minW={['full', '175px']}>
                  <InputField
                    id="streetNo"
                    isRequired={isFieldRequired('streetNo')}
                    fontSize={'xl'}
                    label={t('about:billing.streetNo.label')}
                    placeholder={t('about:billing.streetNo.placeholder')}
                    {...register('streetNo')}
                    error={errors.streetNo}
                  />
                </Box>
              </Stack>
              <Stack direction={['column', 'row']} spacing="24px">
                <Box minW={['full', '175px']}>
                  <InputField
                    id="zip"
                    isRequired={isFieldRequired('zip')}
                    fontSize={'xl'}
                    label={t('about:billing.zip.label')}
                    placeholder={t('about:billing.zip.placeholder')}
                    {...register('zip')}
                    error={errors.zip}
                  />
                </Box>
                <Box w={['full', '368px']}>
                  <InputField
                    id="city"
                    isRequired={isFieldRequired('city')}
                    fontSize={'xl'}
                    label={t('about:billing.city.label')}
                    placeholder={t('about:billing.city.placeholder')}
                    {...register('city')}
                    error={errors.city}
                  />
                </Box>
              </Stack>
              <InputField
                id="area"
                isRequired={isFieldRequired('area')}
                label={t('about:billing.area.label')}
                fontSize={'xl'}
                placeholder={t('about:billing.area.placeholder')}
                {...register('area')}
                error={errors.area}
              />
              <CountrySelect
                id="country"
                isRequired={isFieldRequired('country')}
                fontSize={'xl'}
                label={t('about:billing.country.label')}
                placeholder={t('about:billing.country.placeholder')}
                {...register('country')}
                error={errors.country}
              />
              <InputField
                id="vatNo"
                isRequired={isFieldRequired('vatNo')}
                fontSize={'xl'}
                label={t('about:billing.vatNo.label')}
                placeholder={t('about:billing.vatNo.placeholder')}
                {...register('vatNo')}
                error={errors.vatNo}
                style={{ textTransform: 'uppercase' }}
              />
            </Stack>

            <Divider borderWidth={'1px'} />

            {/* Contact Information */}
            <Stack id="contact-infos" spacing="24px">
              <Heading as="h2" fontSize="xl" mb="12px">
                {t('about:contact.header')}
              </Heading>
              <InputField
                id="email"
                isRequired={isFieldRequired('email')}
                fontSize={'xl'}
                label={t('about:contact.email.label')}
                placeholder={t('about:contact.email.placeholder')}
                {...register('email')}
                type="email"
                error={errors.email}
              />
              <InputField
                id="tel"
                isRequired={isFieldRequired('tel')}
                fontSize={'xl'}
                label={t('about:contact.tel.label')}
                placeholder={t('about:contact.tel.placeholder')}
                {...register('tel')}
                type="tel"
                error={errors.tel}
              />
            </Stack>

            <Box>
              <Text mb={16}>{t('form:mandatoryFieldsNote')}</Text>
            </Box>
          </Stack>
        </Flex>
      </form>
      <StickyFooter
        onBack={() => goPrev()}
        onNext={handleSubmit(goNextPage)}
        isLoading={isLoading}
      />
    </FormProvider>
  );
};
