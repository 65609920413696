import * as yup from 'yup';
import { EMAIL_REGEX } from './constants/regex';

// Override the default yup library's email function with a custom Regex adapted to Taxdoo
yup.addMethod(yup.string, 'email', function validateEmail(message) {
  return this.matches(EMAIL_REGEX, {
    message,
    name: 'email',
    excludeEmptyString: true,
  });
});

export default yup;
