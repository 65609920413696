import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

export type Pages = 0 | 1 | 2 | 3 | 4;

interface PageContextValues {
  currentPage: Pages;
  setPage: (page: Pages) => void;
  goPrev: () => void;
  goNext: () => void;
}

const PageContext = createContext<PageContextValues>({
  currentPage: 0,
  setPage: () => undefined,
  goPrev: () => undefined,
  goNext: () => undefined,
});

export const PageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [currentPage, setPage] = useState<Pages>(0);
  // Scroll to top when current page changes.
  useEffect(() => window.scrollTo(0, 0), [currentPage]);

  const goPrev = () => {
    setPage((prevPage) => {
      if (prevPage !== 0) {
        return (prevPage - 1) as Pages;
      }

      return prevPage;
    });
  };

  const goNext = () => {
    setPage((prevPage) => {
      if (prevPage !== 4) {
        return (prevPage + 1) as Pages;
      }

      return prevPage;
    });
  };

  const values: PageContextValues = {
    currentPage,
    setPage,
    goPrev,
    goNext,
  };

  return <PageContext.Provider value={values}>{children}</PageContext.Provider>;
};

export const usePage = () => useContext(PageContext);
