import { Input, InputProps } from '@chakra-ui/react';
import { FieldProps } from '@types';
import React from 'react';
import FormField from './FormField';

export type Props = InputProps & FieldProps;

/**
 * Renders the chakra-ui Input component with label and error message support
 */
export const InputField = React.forwardRef<HTMLInputElement, Props>(
  ({ id, label, isRequired, error, error_msg, ...props }, ref) => (
    <FormField {...{ id, label, isRequired, error, error_msg }}>
      <Input
        {...{ id, ref, ...props }}
        size={props.size || 'lg'}
        focusBorderColor="#0F8CC8"
      />
    </FormField>
  ),
);

InputField.displayName = 'InputField';
export default InputField;
