import '@fontsource/jost';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { theme } from '@taxdoo/ui';
import type { AppProps } from 'next/app';
import { BookingFormProvider } from 'context/formContext/formContext';
import { PageProvider } from 'context/pageContext/pageContext';
import { PriceProvider } from 'context/priceContext/priceContext';
import { SEOController } from 'context/SEOController/SEOController';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import { AwsRumService } from 'services/cloudWatchRUMService';
import { AwsRum } from 'aws-rum-web';
import React from 'react';

const extendedTheme = extendTheme(theme, {
  components: {
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            background: 'taxdooBlue.900',
            borderColor: 'taxdooBlue.900',
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            background: 'taxdooBlue.900',
            borderColor: 'taxdooBlue.900',
          },
        },
      },
    },
  },
});

type ConsentAnswer = 'ACCEPT' | 'DENY';
type ConsentObject = {
  ESSENTIAL: ConsentAnswer;
  STORAGE: ConsentAnswer;
  MARKETING: ConsentAnswer;
  PERSONALIZATION: ConsentAnswer;
  ANALYTICS: ConsentAnswer;
  OPT_OUT: ConsentAnswer;
};

let awsRum: AwsRum | undefined;

function MyApp({ Component, pageProps }: AppProps) {
  React.useEffect(() => {
    // disable CWR in localhost
    awsRum = location.hostname !== 'localhost' ? AwsRumService() : undefined;
    cookieConsentHandler();
  }, []);

  /**
   * Listen and handles consent preferences changes
   */
  const cookieConsentHandler = () => {
    // This event will dispatch when the visitor saves their consent preferences
    // https://docs.osano.com/developer-documentation-consent-javascript-api#osano-cm-consent-saved
    window.Osano?.cm?.addEventListener(
      'osano-cm-consent-saved',
      (change: ConsentObject) => {
        switch (true) {
          case change.ANALYTICS === 'ACCEPT':
            awsRum?.allowCookies(true);
            break;
          case change.ANALYTICS === 'DENY':
            awsRum?.allowCookies(false);
            break;
        }
      },
    );
  };

  return (
    <ErrorBoundary awsRum={awsRum}>
      <PageProvider>
        <BookingFormProvider>
          <PriceProvider>
            <SEOController>
              <ChakraProvider theme={extendedTheme}>
                <Component {...pageProps} />
              </ChakraProvider>
            </SEOController>
          </PriceProvider>
        </BookingFormProvider>
      </PageProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
