enum Packages {
  professional = 'professional',
  premium = 'premium',
}

export type PackagesType = keyof typeof Packages;

export enum Addons {
  oss = 'oss',
  invoice = 'invoice',
  datev = 'datev',
  datevPayments = 'datevPayments',
}
export type AddonsNames = keyof typeof Addons;

type Product = {
  name: string;
  description: string;
  unitPrice: number;
  currency: string;
};

type Package = {
  products: Product[];
  unitPrice: Map<string, number | undefined>;
};

export const packageGmvMap: Record<PackagesType, Package> = {
  professional: {
    products: [
      {
        name: Addons.datev,
        description: 'DATEV sales export',
        unitPrice: 99,
        currency: 'EUR',
      },
      {
        name: Addons.datevPayments,
        description: 'DATEV payment export',
        unitPrice: 0,
        currency: 'EUR',
      },
      {
        name: 'filing',
        description: 'Compliance Package',
        unitPrice: 99,
        currency: 'EUR',
      },
      {
        name: Addons.oss,
        description: 'OSS Package',
        unitPrice: 99,
        currency: 'EUR',
      },
      {
        name: Addons.invoice,
        description: 'Pro-Forma Invoices',
        unitPrice: 99,
        currency: 'EUR',
      },
    ],
    unitPrice: new Map([
      ['25000', 49],
      ['50000', 69],
      ['100000', 99],
      ['200000', 149],
      ['400000', 249],
      ['400001', 349],
    ]),
  },

  premium: {
    products: [
      {
        name: Addons.datev,
        description: 'DATEV sales export',
        unitPrice: 99,
        currency: 'EUR',
      },
      {
        name: Addons.datevPayments,
        description: 'DATEV payment export',
        unitPrice: 0,
        currency: 'EUR',
      },
      {
        name: 'filing',
        description: 'Compliance Package',
        unitPrice: 99,
        currency: 'EUR',
      },
      {
        name: Addons.oss,
        description: 'OSS Package',
        unitPrice: 99,
        currency: 'EUR',
      },
      {
        name: Addons.invoice,
        description: 'Pro-Forma Invoices',
        unitPrice: 99,
        currency: 'EUR',
      },
    ],
    unitPrice: new Map([
      ['25000', 299],
      ['50000', 349],
      ['100000', 399],
      ['200000', 499],
      ['400000', 649],
      ['400001', 799],
    ]),
  },
};
