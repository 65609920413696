import { FlagIconProps } from '@types';

export function FlagDK({ width = '40', height = '26', ...props }: FlagIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 72 72" {...props}>
      <path fill="#d22f27" d="M5 17h62v38H5z"></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M67 33H30V17h-6v16H5v6h19v16h6V39h37v-6z"></path>
    </svg>
  );
}
