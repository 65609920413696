export async function validateVatID(value: string | undefined): Promise<boolean> {
  // save some api calls
  if (!value) {
    return false;
  }

  try {
    const country = value.slice(0, 2);
    const result: Response = await fetch(
      `/api/vat-id/validation?vatNo=${value}&country=${country}`,
    );

    // hide any errors that might have occurred
    if (!result.ok) {
      return true;
    }

    // Only return invalid if the api has successfully determined that the vatId is invalid
    const json = await result?.json();
    if (json.status == 'invalid') {
      return false;
    }
  } catch (error) {}

  return true;
}
