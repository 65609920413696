import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
  Container,
  Flex,
  Link,
  Text,
} from '@chakra-ui/react';
import { Box, Heading, List, ListIcon, ListItem, Stack } from '@chakra-ui/react';
import { CheckBlue } from '@Icons';
import { useTranslation } from 'react-i18next';
import { useBreakpointValue } from '@chakra-ui/react';

export const PackageFeatures = () => {
  const { t } = useTranslation(['package']);

  const firstFeaturesList = t('package:features.firstList', {
    returnObjects: true,
  });
  const secondFeaturesList = t('package:features.secondList', {
    returnObjects: true,
  });

  const FeaturesList = (
    <>
      <Stack direction={{ base: 'column', md: 'row' }} justify="space-between" mb={20}>
        <List spacing={6} flex={1}>
          {firstFeaturesList.map((item) => (
            <ListItem key={item} display={'flex'} alignItems={'center'} gap={2}>
              <ListIcon as={CheckBlue} />
              <Text>{item}</Text>
            </ListItem>
          ))}
        </List>

        <List spacing={6} flex={1}>
          {secondFeaturesList.map((item) => (
            <ListItem key={item} display="flex" alignItems="center" gap={2}>
              <ListIcon as={CheckBlue} />
              <Text>{item}</Text>
            </ListItem>
          ))}
        </List>
      </Stack>
      <Center>
        <Link
          onClick={() => window?.dataLayer?.push({ event: 'booking-p1-comparison' })}
          color="taxdooBlue.600"
          href={t('package:features.link.url')}
          isExternal
          fontSize={{ base: '18px' }}
          fontWeight={'semibold'}>
          {t('package:features.link.text')} <ExternalLinkIcon mx="2px" />
        </Link>
      </Center>
    </>
  );

  const DesktopView = (
    <Box>
      <Heading mb={8}>{t('package:features.headline')}</Heading>
      {FeaturesList}
    </Box>
  );

  const MobileView = (
    <Accordion allowMultiple w="full">
      <AccordionItem borderStyle="none">
        <AccordionButton p={0}>
          <Flex
            w="full"
            textAlign="left"
            justifyContent="space-between"
            alignItems="center">
            <Heading fontSize="2xl">{t('package:features.headline')}</Heading>
            <AccordionIcon />
          </Flex>
        </AccordionButton>
        <AccordionPanel pt={8} px={0}>
          {FeaturesList}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );

  return (
    <Center w="full" bg={'taxdooBlue.50'}>
      <Container
        maxW={'container.xl'}
        px={{ base: 6, lg: '128px' }}
        py={{ base: 8, lg: 16 }}>
        {useBreakpointValue({
          base: MobileView,
          lg: DesktopView,
        })}
      </Container>
    </Center>
  );
};
