import { AwsRum } from 'aws-rum-web';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  awsRum: AwsRum | undefined;
}

/**
 * Catch unhandled errors and send them to cloudwatch RUM
 */
class ErrorBoundary extends Component<Props> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.awsRum?.recordError(error);
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
