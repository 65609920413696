import { AwsRum, AwsRumConfig } from 'aws-rum-web';

let awsRum: AwsRum | undefined;
/**
 * Instantiate the CloudWatch RUM web client and begin monitoring the application.
 * @returns AWS RUM service instance
 */
export function AwsRumService(): AwsRum | undefined {
  if (!awsRum) {
    try {
      const config: AwsRumConfig = {
        guestRoleArn: process.env.NEXT_PUBLIC_RUM_GUEST_ROLE_ARN,
        identityPoolId: process.env.NEXT_PUBLIC_RUM_POOL_ID,
        endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
        telemetries: ['performance', 'errors', ['http', { addXRayTraceIdHeader: true }]],
        enableXRay: true,
      };

      const APPLICATION_ID = process.env.NEXT_PUBLIC_RUM_APP_ID as string;
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'eu-central-1';

      awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config,
      );

      return awsRum;
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }
  return awsRum;
}
