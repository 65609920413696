import { useBookingForm } from 'context/formContext/formContext';
import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { Addons, packageGmvMap } from './../../utils/constants/packageGmvMap';
interface PriceContextValues {
  prices: {
    package?: number;
    filings: number;
    registrations: number;
    datev: number;
    datevPayments: number;
    invoice: number;
    oss: number;
  };
  packageTotalPrice: number;
  addonsTotalPrice: number;
  total: number;
}

const PriceContext = createContext<PriceContextValues>({
  prices: {
    package: undefined,
    filings: 0,
    registrations: 0,
    datev: 0,
    datevPayments: 0,
    invoice: 0,
    oss: 0,
  },
  packageTotalPrice: 0,
  addonsTotalPrice: 0,
  total: 0,
});

export const PriceProvider: FC<PropsWithChildren> = ({ children }) => {
  const { subscribeForm, addonsForm } = useBookingForm();
  const { package: pkg, sales } = subscribeForm.watch();
  const { filings, registrations, ...addons } = addonsForm.watch();

  const prices: PriceContextValues['prices'] = {
    package: undefined,
    filings: 0,
    registrations: 0,
    datev: 0,
    datevPayments: 0,
    invoice: 0,
    oss: 0,
  };

  if (pkg) {
    const currentPriceMap = packageGmvMap[pkg];

    if (sales) {
      prices.package = currentPriceMap.unitPrice.get(sales) || undefined;
    }

    currentPriceMap.products.map(({ name, unitPrice }) => {
      if (name === 'filing' && filings) {
        prices.filings = unitPrice * filings.length;
      }

      if (name === 'registrations' && registrations) {
        prices.registrations = unitPrice * registrations.length;
      }

      if (name === Addons.datev && addons.datev) {
        prices.datev = unitPrice;
      }

      if (name === Addons.datevPayments && addons.datevPayments) {
        prices.datevPayments = unitPrice;
      }

      if (name === Addons.invoice && addons.invoice) {
        prices.invoice = unitPrice;
      }

      if (name === Addons.oss && addons.oss) {
        prices.oss = unitPrice;
      }
    });
  }

  const packageTotalPrice = prices.package || 0;
  const addonsTotalPrice =
    prices.datev +
    prices.datevPayments +
    prices.invoice +
    prices.oss +
    prices.filings +
    prices.registrations;

  const total = packageTotalPrice + addonsTotalPrice;

  return (
    <PriceContext.Provider value={{ prices, packageTotalPrice, addonsTotalPrice, total }}>
      {children}
    </PriceContext.Provider>
  );
};

export const usePrice = () => useContext(PriceContext);
