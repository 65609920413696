// allow users to enter a telephone number with common special characters and will strip these away in the backend validation
export const PHONE_NUMBER_REGEX = /^(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+){4,}$/;
export const VAT_NUMBER_REGEX =
  /^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)([0-6|9]\d{7}[A-Z])|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9,12}([0-9]{0,3})|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(HR)(\d{11})|(IE)(\d{7}[A-Z][A-Z]{0,1})|(IE)([7-9][A-Z*+)]\d{5}[A-W])|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)(\d{9})B\d{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10}|(XI)[0-9]{9,12})$/i;
export const NUMBERS_ONLY = /^\d+$/;
export const COMPANY_NAME_REGEX = /^([A-Za-z\d\s\-&.,+/'()#!:À-ÿ[\]]*)$/gi;
export const CUSTOM_LATEN_REGEX = /^([a-zA-ZÀ-ÿ\s.-]*)$/gi;
export const ZIP_REGEX = /^([\wÀ-ÿ\s-]*)$/gi;
export const STREET_NO_REGEX = /^([\wÀ-ÿ\s]*)$/gi;

// from https://taxdoo.atlassian.net/wiki/spaces/Technology/pages/3927539719/Input+validation#Email
export const EMAIL_REGEX =
  /^[a-zA-ZÀ-ž0-9.!#$%&'*+/=?^_`{|}~-]+@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
