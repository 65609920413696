import { FieldError } from 'react-hook-form/dist/types/errors';

export type CountryCode =
  | 'AT'
  | 'BE'
  | 'BG'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DK'
  | 'EE'
  | 'ES'
  | 'FI'
  | 'FR'
  | 'GB'
  | 'GR'
  | 'HR'
  | 'HU'
  | 'IE'
  | 'IT'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'MT'
  | 'NL'
  | 'PL'
  | 'PT'
  | 'RO'
  | 'SE'
  | 'SI'
  | 'SK'
  | 'XI';

export enum CountryCodes {
  AT = 'at',
  BE = 'be',
  CZ = 'cz',
  FR = 'fr',
  DE = 'de',
  IT = 'it',
  LU = 'lu',
  NL = 'nl',
  PL = 'pl',
  ES = 'es',
  SE = 'se',
  UK = 'gb',
  DK = 'dk',
  IE = 'ie',
}
export type FlagIconProps = {
  width?: string;
  height?: string;
  active?: boolean;
  [x: string]: unknown;
};

export type FieldProps = {
  id: string;
  label: string;
  isRequired?: boolean;
  error?: FieldError;
  tooltip?: string;
  error_msg?: string;
};

export interface Address {
  streetNo?: string;
  street?: string;
  zip?: string;
  city?: string;
  area?: string;
  country?: string;
}

export enum EntityType {
  /** Corporation (SE, AG, GmbH, UG (limited liability)) */
  Legal = 'legal',
  /** Sole proprietorship (including e.K.) */
  Natural = 'natural',
  /** Other partnership (OHG, GbR, KG) */
  Partnership = 'partnership',
  /** (Hybrid) A partnership with a legal person acting as a complementary (e.g. the german "& Co. KG"). */
  PartnershipComplementary = 'partnership_complementary',
}

/**
 * The base package for the new client.
 */
export enum BasePackage {
  Premium = 'premium',
  Professional = 'professional',
}

export enum Pricing {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

export enum LanguageCode {
  De = 'de',
  En = 'en',
}
/**
 * Number formate locale
 */
export enum LanguageFormatLocale {
  de = 'de-DE',
  en = 'en-GB',
}

export enum Salutation {
  Mr = 'Mr',
  Ms = 'Ms',
  None = 'None',
}

export interface FormValues {
  companyName: string;
  entityType: EntityType;
  salutation: string;
  surname: string;
  name: string;
  dateOfBirth?: string;

  address?: string;
  street: string;
  streetNo: string;
  zip: string;
  city: string;
  area: string;
  country: string;

  email: string;
  tel: string;
  vatNo: string;

  sales: string;
  package: BasePackage;
  pricing: Pricing;
  filings?: string[];
  registrations?: string[];

  // Extras
  datev?: boolean;
  invoice?: boolean;
  oss?: boolean;
  intrastatDeOutbound?: boolean;
  intrastatDeInbound?: boolean;

  // Interfaces
  afterbuy?: boolean;
  amazon?: boolean;
  billbee?: boolean;
  dailyAmazon?: boolean;
  ebay?: boolean;
  jtlCloud?: boolean;
  jtlOnPremise?: boolean;
  none?: boolean;
  plentymarkets?: boolean;
  shopify?: boolean;

  language: LanguageCode;
  leadSourceAnswer?: string;

  captchaClientKey?: string;

  couponCode?: string;
}

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[];
    /**
     * Unique identifier used by marketing
     */
    anonymizedTestId?: string;
    Osano: any | undefined;
  }
}
