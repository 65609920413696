export const Logo = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M5.16602 4V23.84H10.6908L25.11 4H5.16602Z" fill="#76EBC0" />
      <path d="M31.4316 31.12V11.28H25.8524L11.4316 31.12H31.4316Z" fill="#00BDFF" />
      <path d="M28.0707 0L1.83984 36H7.91137L34.1598 0H28.0707Z" fill="black" />
    </svg>
  );
};
