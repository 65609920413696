import { FlagIconProps } from '@types';

export const FlagDE = ({
  width = '26',
  height = '18',
  active = false,
  ...props
}: FlagIconProps) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={active ? '1' : '0.3'}>
        <g>
          <path d="M26.3334 1H-0.333313V17H26.3334V1Z" fill="black" />
          <path d="M26.3334 6.33398H-0.333313V17.0007H26.3334V6.33398Z" fill="#DD0000" />
          <path d="M26.3334 11.666H-0.333313V16.9993H26.3334V11.666Z" fill="#FFCE00" />
        </g>
        <rect x="0.5" y="0.5" width="25" height="17" rx="1.5" stroke="#E1E1E1" />
      </g>
    </svg>
  );
};
