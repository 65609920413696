export const CheckBlue = () => (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.00012 10.1925L2.50012 6.6925C2.11012 6.3025 1.49012 6.3025 1.10012 6.6925C0.710117 7.0825 0.710117 7.7025 1.10012 8.0925L5.29012 12.2825C5.68012 12.6725 6.31012 12.6725 6.70012 12.2825L17.3001 1.6925C17.6901 1.3025 17.6901 0.6825 17.3001 0.2925C16.9101 -0.0975 16.2901 -0.0975 15.9001 0.2925L6.00012 10.1925Z"
      fill="#0F8CC8"
    />
  </svg>
);
