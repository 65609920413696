import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';
import { useBookingForm } from '../formContext/formContext';
import { usePrice } from '../priceContext/priceContext';
import { usePage } from '../pageContext/pageContext';
import dataLayerHelper from 'utils/helpers/dataLayer';
import { BasePackage } from '@types';
import { ServiceAddonsCountries } from '@components/AddonsForm/AddonsForm';
import { getCurrentScrollPercentage } from 'utils/helpers/getCurrentScrollPrecentage';
import {
  GMV,
  PACKAGES,
  PRO_AND_PREMIUM_PACKAGES,
  FILING_AND_REG_COUNTRIES,
  LANGS,
} from 'utils/constants/deepLink';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Addons, AddonsNames } from 'utils/constants/packageGmvMap';

const SEOControllerContext = createContext<null>(null);

const pageNames = ['plan', 'addons', 'about-you', 'recap', 'referral'];

const urlParamsSchema = yup.object().shape({
  gmv: yup.number().oneOf(GMV).notRequired(),
  pkg: yup
    .string()
    .oneOf(PACKAGES)
    .when('gmv', (gmv, schema) => {
      if (gmv > Number(50000)) {
        return schema.transform((value: string) => {
          return value;
        });
      }

      return schema;
    })
    .notRequired(),
  addon: yup
    .array(yup.string().oneOf(PRO_AND_PREMIUM_PACKAGES))
    .when('pkg', (pkg, schema) => {
      if (['professional', 'premium'].includes(pkg)) {
        return schema.compact((v: AddonsNames) => !PRO_AND_PREMIUM_PACKAGES.includes(v));
      }

      return schema;
    }),
  filing: yup
    .array(yup.string().oneOf(FILING_AND_REG_COUNTRIES))
    .when('pkg', (pkg, schema) => {
      if (['professional', 'premium'].includes(pkg)) {
        return schema.compact((v: string) => !FILING_AND_REG_COUNTRIES.includes(v));
      }

      return schema;
    }),
  reg: yup
    .array(yup.string().oneOf(FILING_AND_REG_COUNTRIES))
    .when('pkg', (pkg, schema) => {
      if (['professional', 'premium'].includes(pkg)) {
        return schema.compact((v: string) => !FILING_AND_REG_COUNTRIES.includes(v));
      }

      return schema;
    }),

  lang: yup.string().oneOf(LANGS).notRequired(),
});

export const SEOController: FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();
  const { subscribeForm, addonsForm } = useBookingForm();
  const { prices } = usePrice();
  const { currentPage } = usePage();
  const [maxScrollPercentage, setMaxScrollPercentage] = useState(0);

  const { watch: subscribeWatch } = subscribeForm;
  const { watch: addonsWatch } = addonsForm;

  const { sales, package: pkg } = subscribeWatch();
  const { datev, oss, invoice, datevPayments, registrations, filings } = addonsWatch();

  const onScroll = () => {
    const currentScroll = getCurrentScrollPercentage();

    if (currentScroll > maxScrollPercentage) {
      setMaxScrollPercentage(currentScroll);
    }
  };

  // DEEPLINK
  useEffect(() => {
    const searchParams = new URL(window.location.href).searchParams;

    const searchData = {
      gmv: searchParams.get('gmv') || undefined,
      pkg: searchParams.get('pkg') || undefined,
      addon: [...searchParams.getAll('addon'), ...searchParams.getAll('addons')],
      filing: searchParams.getAll('filing') || [],
      reg: searchParams.getAll('reg') || [],
      lang: searchParams.get('lang') || i18n.language,
    };

    try {
      const parsedSearchData = urlParamsSchema.validateSync(searchData);

      subscribeForm.setValue(
        'sales',
        parsedSearchData.gmv ? String(parsedSearchData.gmv) : undefined,
      );

      subscribeForm.setValue(
        'package',
        parsedSearchData.pkg ? (parsedSearchData.pkg as BasePackage) : undefined,
      );

      parsedSearchData.addon?.map((addon) => {
        addonsForm.setValue(addon as AddonsNames, true);

        // Select the DATEV addon if the payment export addon is present
        if (addon === Addons.datevPayments) {
          addonsForm.setValue(Addons.datev as AddonsNames, true);
        }
      });

      parsedSearchData.reg &&
        addonsForm.setValue('registrations', parsedSearchData.reg as string[]);

      parsedSearchData.filing &&
        addonsForm.setValue('filings', parsedSearchData.filing as string[]);

      // Add filings if registration is set
      parsedSearchData.reg?.forEach((countryCode) => {
        if (parsedSearchData.filing?.indexOf(countryCode) === -1) {
          if (countryCode) {
            //update the filing array
            parsedSearchData.filing.push(countryCode);
            //update the form
            addonsForm.setValue('filings', parsedSearchData.filing as string[]);
          }
        }
      });

      parsedSearchData.lang && i18n.changeLanguage(parsedSearchData.lang);
    } catch (error) {
      console.warn('Error: one or more values in the URL are invalid.');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // GMV
  useEffect(() => {
    const currentDataLayerGmv = dataLayerHelper.products.find((product) =>
      product.id.includes('gmv_'),
    );

    if (currentDataLayerGmv) {
      dataLayerHelper.remove(currentDataLayerGmv.id);
      sales && dataLayerHelper.add(`gmv ${sales}`, `gmv_${sales}`, '0');
    } else {
      sales && dataLayerHelper.add(`gmv ${sales}`, `gmv_${sales}`, '0');
    }
  }, [sales]);

  // PACKAGE
  useEffect(() => {
    const currentDataLayerPkg = dataLayerHelper.products.find(
      (product) =>
        product.id === BasePackage.Professional || product.id === BasePackage.Premium,
    );

    if (currentDataLayerPkg) {
      dataLayerHelper.remove(currentDataLayerPkg.id);
      pkg && dataLayerHelper.add(pkg, pkg, String(prices.package || 0));
    } else {
      pkg && dataLayerHelper.add(pkg, pkg, String(prices.package || 0));
    }
  }, [prices.package, pkg]);

  // DATEV
  useEffect(() => {
    if (!dataLayerHelper.products.some((product) => product.id === Addons.datev)) {
      if (datev) {
        dataLayerHelper.add(Addons.datev, Addons.datev, String(prices.datev));
      }
    } else {
      if (!datev) {
        dataLayerHelper.remove(Addons.datev);
      }
    }
  }, [datev, prices.datev]);

  // DATEV PAYMENT
  useEffect(() => {
    if (
      !dataLayerHelper.products.some((product) => product.id === Addons.datevPayments)
    ) {
      if (datevPayments) {
        dataLayerHelper.add(
          Addons.datevPayments,
          Addons.datevPayments,
          String(prices.datevPayments),
        );
      }
    } else {
      if (!datevPayments) {
        dataLayerHelper.remove(Addons.datevPayments);
      }
    }
  }, [datevPayments, prices.datevPayments]);

  // OSS
  useEffect(() => {
    if (!dataLayerHelper.products.some((product) => product.id === Addons.oss)) {
      if (oss) {
        dataLayerHelper.add(Addons.oss, Addons.oss, String(prices.oss));
      }
    } else {
      if (!oss) {
        dataLayerHelper.remove(Addons.oss);
      }
    }
  }, [oss, prices.oss]);

  // INVOICE
  useEffect(() => {
    if (!dataLayerHelper.products.some((product) => product.id === Addons.invoice)) {
      if (invoice) {
        dataLayerHelper.add(Addons.invoice, Addons.invoice, String(prices.invoice));
      }
    } else {
      if (!invoice) {
        dataLayerHelper.remove(Addons.invoice);
      }
    }
  }, [invoice, prices.invoice]);

  // FILINGS
  useEffect(() => {
    ServiceAddonsCountries.forEach((countryCode) => {
      if (
        !dataLayerHelper.products.some(
          (product) => product.id === `${countryCode}-filings`,
        )
      ) {
        if (filings?.includes(countryCode)) {
          dataLayerHelper.add(`${countryCode} filings`, `${countryCode}-filings`, '0');
        }
      } else {
        if (!filings?.includes(countryCode)) {
          dataLayerHelper.remove(`${countryCode}-filings`);
        }
      }
    });
  }, [filings]);

  // REGISTRATIONS
  useEffect(() => {
    ServiceAddonsCountries.forEach((countryCode) => {
      if (
        !dataLayerHelper.products.some(
          (product) => product.id === `${countryCode}-registrations`,
        )
      ) {
        if (registrations?.includes(countryCode)) {
          dataLayerHelper.add(
            `${countryCode} registrations`,
            `${countryCode}-registrations`,
            String(prices.registrations),
          );
        }
      } else {
        if (!registrations?.includes(countryCode)) {
          dataLayerHelper.remove(`${countryCode}-registrations`);
        }
      }
    });
  }, [prices.registrations, registrations]);

  // PAGE SCROLL
  useEffect(() => {
    document.addEventListener('scroll', onScroll);

    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';

      window?.dataLayer?.push({
        [`max-scroll-depth-${pageNames[currentPage]}-leave`]: `${maxScrollPercentage}%`,
      });
    });

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SEOControllerContext.Provider value={null}>{children}</SEOControllerContext.Provider>
  );
};
