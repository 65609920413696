import { Addons, AddonsNames } from './packageGmvMap';

export const GMV = [25000, 50000, 100000, 200000, 400000, 400001];

export const PACKAGES = ['professional', 'premium'];

export const PRO_AND_PREMIUM_PACKAGES: AddonsNames[] = [
  Addons.oss,
  Addons.datev,
  Addons.invoice,
  Addons.datevPayments,
];

export const FILING_AND_REG_COUNTRIES = [
  'at',
  'se',
  'es',
  'it',
  'fr',
  'nl',
  'cz',
  'pl',
  'be',
  'lex',
  'gb',
  'de',
  'dk',
  'ie',
];

export const LANGS = ['en', 'de'];
