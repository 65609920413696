import { CountryCodes } from '@types';
import { FlagAT } from './FlagAT';
import { FlagBE } from './FlagBE';
import { FlagCZ } from './FlagCZ';
import { FlagDE } from './FlagDE';
import { FlagES } from './FlagES';
import { FlagFR } from './FlagFR';
import { FlagIT } from './FlagIT';
import { FlagLU } from './FlagLU';
import { FlagNL } from './FlagNL';
import { FlagPL } from './FlagPL';
import { FlagSE } from './FlagSE';
import { FlagUK } from './FlagUK';
import { FlagDK } from './FlagDK';
import { FlagIE } from './FlagIE';

export { Logo } from './Logo';
export { CheckBlue } from './CheckBlue';
export { PhoneIcon } from './PhoneIcon';

export const CountryFlags = {
  [CountryCodes.AT]: FlagAT,
  [CountryCodes.BE]: FlagBE,
  [CountryCodes.CZ]: FlagCZ,
  [CountryCodes.DE]: FlagDE,
  [CountryCodes.ES]: FlagES,
  [CountryCodes.FR]: FlagFR,
  [CountryCodes.IT]: FlagIT,
  [CountryCodes.LU]: FlagLU,
  [CountryCodes.NL]: FlagNL,
  [CountryCodes.PL]: FlagPL,
  [CountryCodes.UK]: FlagUK,
  [CountryCodes.SE]: FlagSE,
  [CountryCodes.DK]: FlagDK,
  [CountryCodes.IE]: FlagIE,
};
