import * as yup from 'yup';
import { TFunction } from 'i18next';
import { BasePackage } from '@types';

export interface SubscriptionFormValues {
  sales?: string;
  package?: BasePackage;
}

export const subscriptionFormDefaults: SubscriptionFormValues = {};

export const subscriptionFormSchema = (t: TFunction) =>
  yup.object().shape({
    sales: yup.string().required(t('form:error.required')),
    package: yup.string().required(t('form:error.required')),
  });
