import { FlagIconProps } from '@types';

export const FlagLU = ({
  width = '26',
  height = '18',
  active = false,
  ...props
}: FlagIconProps) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={active ? '1' : '0.3'}>
        <path d="M25 9H1V17H25V9Z" fill="#00A1DE" />
        <path d="M25 1H1V9H25V1Z" fill="#ED2939" />
        <path d="M25 6.33398H1V11.6673H25V6.33398Z" fill="white" />
        <rect x="0.5" y="0.5" width="25" height="17" rx="1.5" stroke="#E1E1E1" />
      </g>
      <defs>
        <clipPath id="clip0_1476_11801">
          <rect x="1" y="1" width="24" height="16" rx="1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
