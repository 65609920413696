import { FlagIconProps } from '@types';

export function FlagIE({
  width = '40',
  height = '26',
  active = false,
  ...props
}: FlagIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 72 72"
      {...props}
      opacity={active ? '1' : '0.3'}>
      <path fill="#fff" d="M5 17h62v38H5z"></path>
      <path fill="#5c9e31" d="M5 17h21v38H5z"></path>
      <path fill="#e27022" d="M46 17h21v38H46z"></path>
    </svg>
  );
}
