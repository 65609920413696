import { Select, SelectProps } from '@chakra-ui/react';
import { FieldProps } from '@types';
import React from 'react';

import FormField from './FormField';

type Option = {
  label: string;
  value: number | string;
  disabled?: boolean;
};
type SelectFieldProps = SelectProps & FieldProps & { options: Option[] };

/**
 * Renders the chakra-ui Select component with label and error message support
 */
export const SelectField = React.forwardRef<HTMLSelectElement, SelectFieldProps>(
  ({ id, ...props }, ref) => (
    <FormField {...{ id, ...props }}>
      <Select {...{ id, ref, ...props }} size={props.size || 'lg'} fontSize={'xl'}>
        {props.options.map(({ label, value }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </FormField>
  ),
);

SelectField.displayName = 'SelectField';
export default SelectField;
