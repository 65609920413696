import { FlagIconProps } from '@types';

export const FlagUK = ({
  width = '26',
  height = '18',
  active = false,
  ...props
}: FlagIconProps) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={active ? '1' : '0.3'}>
        <g>
          <path d="M-3 1V17H29V1H-3Z" fill="#012169" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.42229 8.99944L-3.71555 2.43053L-2.28446 -0.431641L13 7.21059L28.2845 -0.431641L29.7155 2.43053L16.5777 8.99944L29.7155 15.5684L28.2845 18.4305L13 10.7883L-2.28446 18.4305L-3.71555 15.5684L9.42229 8.99944Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6149 9.00093L-3.47702 1.95499L-2.52296 0.046875L13 7.80836L28.523 0.046875L29.477 1.95499L15.3851 9.00093L29.477 16.0469L28.523 17.955L13 10.1935L-2.52296 17.955L-3.47702 16.0469L10.6149 9.00093Z"
            fill="#C8102E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3333 6.33333V1H15.6667V6.33333H29V11.6667H15.6667V17H10.3333V11.6667H-3V6.33333H10.3333Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4 7.4V1H14.6V7.4H29V10.6H14.6V17H11.4V10.6H-3V7.4H11.4Z"
            fill="#C8102E"
          />
        </g>
        <rect x="0.5" y="0.5" width="25" height="17" rx="1.5" stroke="#E1E1E1" />
      </g>
      <defs>
        <clipPath>
          <rect x="1" y="1" width="24" height="16" rx="1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
