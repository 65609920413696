import { Input, InputProps, Stack, Text, useOutsideClick } from '@chakra-ui/react';
import { Address, FieldProps } from '@types';
import Script from 'next/script';
import { ChangeEvent, forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import FormField from './FormField';

type AddressProps = InputProps &
  FieldProps & {
    /**
     * A function passed from the parent component that gets executed when the user selects an address.
     */
    onSelectValue?: (address: Address) => void;
    /**
     * pass the parent onChange function to preserve its functionality (mainly the onChange from react hook form)
     */
    onChangeHandler?: (event: ChangeEvent) => void;
  };

// Address types defined by Google maps API
// https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
const COUNTRY_TYPE = 'country';
const STATE_TYPE = 'administrative_area_level_1';
const CITY_TYPE = 'locality';
const ZIP_CODE_TYPE = 'postal_code';
const STREET_TYPE = 'route';
const STREET_NUMBER_TYPE = 'street_number';

/**
 * Renders an input field that supports address auto-fill with label and error message support
 * Takes an optional function that gets called when an address is selected (useful to update siblings address fields e.g. country)
 * Address suggestions are powered by Google Maps Autocomplete service.
 */
export const AddressField = forwardRef<HTMLInputElement, AddressProps>(
  (
    { size, onSelectValue, onChangeHandler, placeholder, ...props }: AddressProps,
    ref,
  ): JSX.Element => {
    const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);
    const [selectedPlaceId, setSelectedPlaceId] = useState<string>();

    const { t } = useTranslation(['form']);
    //Reference to the suggestions list
    const listRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const {
      ready,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
      clearCache,
    } = usePlacesAutocomplete({
      // Load the google map script asynchronously
      callbackName: 'initMap',
      debounce: 300,
      // Cache time in seconds
      cache: 5 * 60,
    });

    useEffect(() => {
      if (selectedPlaceId) {
        const parameter = {
          placeId: selectedPlaceId,
          fields: ['address_components'],
        };
        getDetails(parameter)
          .then((details) => {
            const addressDetails: Address = {};
            if (typeof details !== 'string' && details.address_components) {
              for (const el of details.address_components) {
                switch (el.types[0]) {
                  case COUNTRY_TYPE:
                    addressDetails.country = el.short_name;
                    break;
                  case STATE_TYPE:
                    addressDetails.area = el.long_name;
                    break;
                  case CITY_TYPE:
                    addressDetails.city = el.long_name;
                    break;
                  case ZIP_CODE_TYPE:
                    addressDetails.zip = el.short_name;
                    break;
                  case STREET_TYPE:
                    addressDetails.street = el.long_name;
                    break;
                  case STREET_NUMBER_TYPE:
                    addressDetails.streetNo = el.long_name;
                    break;
                }
              }

              if (addressDetails.street) {
                setValue(addressDetails.street, false);
              }

              onSelectValue?.(addressDetails);
              clearSuggestions();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
      return () => {
        clearSuggestions();
        clearCache();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlaceId]);

    // Close the suggestions list when clicking outside
    useOutsideClick({
      ref: listRef,
      handler: () => setIsSuggestionsOpen(false),
    });

    const handelOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      setIsSuggestionsOpen(true);
      onChangeHandler?.(e);
    };

    const renderSuggestions = () => {
      switch (status) {
        case 'ZERO_RESULTS':
          return (
            <Text textColor={'gray.500'} cursor="default">
              {t('form:error.notFound')}
            </Text>
          );
        case 'OK':
          return data.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <Text
                fontSize="lg"
                p="12px 16px"
                mt="0px !important"
                _hover={{ backgroundColor: '#F5F5F5' }}
                key={place_id}
                onClick={() => setSelectedPlaceId(suggestion.place_id)}>
                {`${main_text}, ${secondary_text}`}
              </Text>
            );
          });
        case 'NOT_FOUND':
        case 'INVALID_REQUEST':
        case 'REQUEST_DENIED':
        case 'OVER_QUERY_LIMIT':
        case 'UNKNOWN_ERROR':
          return <Text textColor={'gray.500'}>{t('form:error.addressFieldError')}</Text>;
      }
    };

    return (
      <>
        <Script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyA9Q7AOu4KyJgWVPFSZ-YMCIh1n5AmtYr0&amp;libraries=places&amp;callback=initMap"></Script>
        <FormField {...props}>
          <Stack>
            <Input
              {...{ ref, ...props }}
              onChange={handelOnChange}
              disabled={!ready}
              size={size || 'lg'}
              placeholder={placeholder}
              autoComplete={'off'}
              focusBorderColor="#0F8CC8"
            />
            {status !== '' && isSuggestionsOpen && (
              <Stack
                ref={listRef}
                borderRadius="xl"
                cursor="pointer"
                border="1px"
                borderColor="gray.200"
                padding="1em">
                {renderSuggestions()}
              </Stack>
            )}
          </Stack>
        </FormField>
      </>
    );
  },
);

AddressField.displayName = 'AddressField';
export default AddressField;
