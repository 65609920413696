import { Box, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { FieldProps } from '@types';
import { Controller } from 'react-hook-form';

import FormField from './FormField';

type Props = FieldProps & {
  name: string;
  direction?: 'row' | 'column';
  defaultValue?: string;
  options: {
    label: string;
    value: string;
  }[];
  /**
   * Custom onChange function to run when selection is updated
   */
  onChange?: () => void;
};

/**
 * Renders the chakra-ui RadioGroup component with label and error message support
 */
export const RadioGroupField = ({
  name,
  direction = 'row',
  defaultValue,
  onChange,
  ...props
}: Props): JSX.Element => {
  return (
    <FormField {...props}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup {...field} onChangeCapture={onChange}>
            <Stack direction={direction} spacing="13px">
              {props.options.map(({ value, label }) => (
                <Radio value={String(value)} key={label}>
                  <Box fontSize={'lg'}>{label}</Box>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        )}
      />
    </FormField>
  );
};

export default RadioGroupField;
