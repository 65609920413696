import { FC, useState } from 'react';
import { Box, Flex, Checkbox, Text, Stack, Radio } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { CountryFlags } from '@components/Icons';
import { CountryCodes } from '@types';

export type ServiceAddonValues = {
  filing: boolean;
  filingType: 'switch' | 'reg';
  countryCode: string;
};
interface ServiceAddonProps {
  isDisabled?: boolean;
  value: ServiceAddonValues;
  onChange: (value: ServiceAddonValues) => void;
}

export const ServiceAddon: FC<ServiceAddonProps> = ({ onChange, value, isDisabled }) => {
  const { t } = useTranslation(['addons', 'others']);
  const [addonValue, setAddonValue] = useState<ServiceAddonValues>(value);
  const countries = t('others:countries', { returnObjects: true });
  const { filing, filingType, countryCode } = addonValue;
  const FlagIcon = CountryFlags[countryCode as keyof typeof CountryFlags];

  const onFilingChange = (filingType: 'switch' | 'reg', filing = true) => {
    const newAddonValue = {
      filing,
      filingType,
      countryCode,
    };
    onChange(newAddonValue);
    setAddonValue(newAddonValue);
  };

  return (
    <Box
      width={'100%'}
      background={isDisabled ? '#F5F5F5' : filing ? '#EBF5FF' : '#FFFFFF'}
      boxShadow={
        isDisabled || filing
          ? undefined
          : `
            0 4px 6px -1px rgba(0, 0, 0, .1),
            0 2px 4px -1px rgba(0, 0, 0, .06),
            0 0 0 .5px rgba(0, 0, 0, .08)
            `
      }
      _hover={{
        boxShadow: isDisabled || filing ? 'none' : 'lg',
      }}
      borderRadius={'lg'}
      px={6}
      py={4}>
      <Flex mb={5} justifyContent={'space-between'}>
        <Box>
          <Checkbox
            data-testid={`service-addon-${countryCode}`}
            disabled={isDisabled}
            onChange={() => {
              onFilingChange('switch', !filing);
            }}
            isChecked={filing}>
            <Text color={'#0F0F0F'} fontSize={'lg'} fontWeight={'semibold'} ml={5}>
              {countries[countryCode.toUpperCase() as keyof typeof countries]}
              {countryCode === CountryCodes.DE && (
                <a href="#addon-form-footnote-2">
                  <sup> 2</sup>
                </a>
              )}
            </Text>
          </Checkbox>
        </Box>
        <Box>{FlagIcon && <FlagIcon active={!isDisabled} />}</Box>
      </Flex>
      <Stack direction={'column'} pl={10} spacing={4}>
        <Radio
          data-testid={`service-addon-${countryCode}-filing`}
          fontSize={'lg'}
          lineHeight={'28px'}
          value="switch"
          color={filing ? '#0F0F0F' : '#BEBEBE'}
          onChange={() => onFilingChange('switch')}
          isChecked={filing && filingType === 'switch'}
          isDisabled={!filing}>
          {t('addons:serviceAddons.filing')}
        </Radio>
        <Radio
          data-testid={`service-addon-${countryCode}-registration`}
          fontSize={'lg'}
          lineHeight={'28px'}
          value="reg"
          color={filing ? '#0F0F0F' : '#BEBEBE'}
          onChange={() => onFilingChange('reg')}
          isChecked={filing && filingType === 'reg'}
          isDisabled={!filing}>
          <Trans
            t={t}
            i18nKey="addons:serviceAddons.registration"
            components={{
              1: <a href="#addon-form-footnote-1"></a>,
              sup: <sup></sup>,
            }}
          />
        </Radio>
      </Stack>
    </Box>
  );
};
