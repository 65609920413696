import { FC, useState } from 'react';

import {
  Container,
  Flex,
  Portal,
  Text,
  IconButton,
  Button,
  HStack,
  Tooltip,
} from '@chakra-ui/react';
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronDownIcon,
  CloseIcon,
  InfoOutlineIcon,
} from '@chakra-ui/icons';
import { BasePackage } from '../../utils/types';

import { useBookingForm } from 'context/formContext/formContext';
import { usePage } from 'context/pageContext/pageContext';
import { usePrice } from 'context/priceContext/priceContext';
import { PurchaseSummary } from '@components/PurchaseSummary';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StickyFooterProps {
  onNext?: () => void;
  onBack?: () => void;
  /**
   * Disable the next button
   */
  disableNext?: boolean;
  /**
   * Show a spinner and disable the next button
   */
  isLoading?: boolean;
  /**
   * Disable the expand (open/close accordion) button
   */
  disableOpen?: boolean;
  tooltip?: string;
}

export const StickyFooter: FC<StickyFooterProps> = ({
  onBack,
  onNext,
  disableNext = false,
  disableOpen = false,
  isLoading = false,
  tooltip,
}) => {
  const [t] = useTranslation(['footer', 'form']);
  const { currentPage } = usePage();
  const { subscribeForm } = useBookingForm();
  const { total } = usePrice();
  const currentPackage = subscribeForm.watch('package');

  const [isPriceBreakDownSectionOpen, setIsPriceBreakDownSectionOpen] = useState(false);

  return (
    <Portal>
      <Container
        position={'fixed'}
        transform={isPriceBreakDownSectionOpen ? 'none' : 'translateY(100%)'}
        translateY={isPriceBreakDownSectionOpen ? '0' : '100%'}
        bottom={{
          base: isPriceBreakDownSectionOpen ? '20' : '0',
          md: isPriceBreakDownSectionOpen ? '28' : '0',
        }}
        zIndex={isPriceBreakDownSectionOpen ? 'modal' : 'auto'}
        opacity={isPriceBreakDownSectionOpen ? '1' : '0'}
        minW={'100vw'}
        bg={'white'}
        transition={'all 0.2s ease-in-out'}
        backgroundColor={'#FAFAFA'}
        boxShadow={'0 -2px 5px #e3e3e8'}
        boxSizing={'border-box'}
        centerContent>
        <Container
          maxW={'container.lg'}
          px="4"
          py={'40px'}
          boxSizing={'border-box'}
          display={'flex'}
          justifyContent={{
            base: 'center',
            md: 'space-between',
          }}>
          <PurchaseSummary showTotal={true} isCompact={true} />
          <Flex
            display={{
              base: 'none',
              md: 'flex',
            }}
            alignItems={'flex-start'}>
            <IconButton
              variant={'ghost'}
              icon={<CloseIcon color={'#0F8CC8'} />}
              aria-label="close-button"
              size={'s'}
              onClick={() => setIsPriceBreakDownSectionOpen(false)}
            />
          </Flex>
        </Container>
      </Container>

      <Container
        minW={'100vw'}
        position={'fixed'}
        bottom={0}
        centerContent
        bg={'white'}
        zIndex={'modal'}
        borderTop={isPriceBreakDownSectionOpen ? '2px solid #e3e3e8' : ''}
        boxShadow={!isPriceBreakDownSectionOpen ? '0 -2px 5px #e3e3e8' : ''}>
        <Container
          maxW={'container.lg'}
          px={'2'}
          h={{
            base: '20',
            md: '28',
          }}
          p={'4'}
          boxSizing="border-box"
          display={'flex'}
          alignItems={'center'}>
          <Flex width={'100%'} justifyContent={'space-between'}>
            {/* Left - Price Details Section */}
            <Flex flexDirection={'column'}>
              <Text
                data-testid="sticky-footer-total-price"
                fontSize={{
                  base: '24px',
                  md: '3xl',
                }}
                fontWeight={'extrabold'}
                display={'flex'}
                alignItems={'center'}>
                {currentPackage ? `${total} €` : '__ €'}
                {!disableOpen && (
                  <ChevronDownIcon
                    strokeWidth={2}
                    onClick={() =>
                      setIsPriceBreakDownSectionOpen(!isPriceBreakDownSectionOpen)
                    }
                    cursor={disableOpen ? 'inherit' : 'pointer'}
                    transform={
                      isPriceBreakDownSectionOpen ? 'rotate(-180deg)' : 'rotate(0)'
                    }
                    transition="all 0.2s ease-in-out"
                    color={'#0F8CC8'}
                  />
                )}
              </Text>
              <Text
                display={{
                  base: 'none',
                  md: 'flex',
                }}
                fontSize={'md'}>
                {currentPackage ? (
                  currentPackage === BasePackage.Premium ? (
                    t('footer:sticky.yearlyContract')
                  ) : (
                    <>
                      {t('footer:sticky.monthlyContract')}
                      <Tooltip
                        label={t('footer:sticky.pricingChangeWarning')}
                        hasArrow
                        placement="top"
                        bg="info.600"
                        shouldWrapChildren>
                        <InfoOutlineIcon ml={'5px'} mb={'2px'} />
                      </Tooltip>
                    </>
                  )
                ) : (
                  t('footer:sticky.payNoPackage')
                )}
              </Text>
            </Flex>

            {/* Right - Navigation Buttons Sections */}
            <Flex id="sticky-footer-right-section">
              <HStack spacing={6}>
                {currentPage !== 0 && (
                  <Button
                    data-testid="sticky-footer-back-btn"
                    fontSize={'lg'}
                    lineHeight={'24px'}
                    borderRadius={'lg'}
                    leftIcon={<ArrowBackIcon />}
                    variant="link"
                    onClick={onBack}>
                    {t('form:controls.back')}
                  </Button>
                )}
                {currentPage !== 4 && (
                  <Tooltip
                    hasArrow
                    label={
                      disableNext ? tooltip || t('footer:sticky.tooltip.required') : null
                    }
                    placement="top"
                    bg="info.600"
                    shouldWrapChildren
                    mt="3">
                    <Button
                      data-testid="sticky-footer-next-btn"
                      fontSize={'lg'}
                      lineHeight={'24px'}
                      borderRadius={'lg'}
                      rightIcon={disableNext ? <InfoOutlineIcon /> : <ArrowForwardIcon />}
                      onClick={onNext}
                      isLoading={isLoading}
                      isDisabled={disableNext}>
                      {t('form:controls.continue')}
                    </Button>
                  </Tooltip>
                )}
              </HStack>
            </Flex>
          </Flex>
        </Container>
      </Container>
    </Portal>
  );
};
