import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { FieldProps } from '@types';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = FieldProps & {
  children: React.ReactNode;
};

/**
 * A wrapper around form fields that adds uniform styling for labels and errors
 */
export const FormField = ({
  id,
  label,
  isRequired,
  error,
  children,
}: Props): JSX.Element => {
  const { t } = useTranslation(['form']);
  const errorMessage = t('form:error.invalid.default');
  return (
    <FormControl isInvalid={Boolean(error)} isRequired={isRequired}>
      <FormLabel fontSize="md" fontWeight="bold" htmlFor={id}>
        {label}
      </FormLabel>
      {children}
      {error && <FormErrorMessage>{error?.message || errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormField;
