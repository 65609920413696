import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Link, Portal } from '@chakra-ui/react';

export const Footer: FC = () => {
  const { t } = useTranslation(['footer']);

  const links = t('footer:links', {
    returnObjects: true,
  }).map(({ label, value }) => ({
    label,
    value,
  }));

  return (
    <Portal>
      <Flex
        as={'footer'}
        justifyContent="center"
        w={'full'}
        bottom={0}
        marginBottom={{
          base: '20',
          md: '28',
        }}
        h={'60px'}
        gap={'32px'}
        borderTop={'2px solid #E1E1E1'}
        bgColor="white">
        {links.map(({ label, value }) => (
          <Link
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            key={label}
            isExternal
            href={value}
            fontSize={'lg'}>
            {label}
          </Link>
        ))}
      </Flex>
    </Portal>
  );
};
