import * as yup from 'yup';

export interface OrderFormValues {
  couponCode?: string;
  hasConsented?: boolean;
}

export const orderFormDefaults: OrderFormValues = {};
export const orderFormSchema = yup.object({
  hasConsented: yup.boolean().required(),
  couponCode: yup.string(),
});
