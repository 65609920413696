import { FC, useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Pages } from 'context/pageContext/pageContext';
import { SubscriptionFormValues } from 'context/formContext/subscriptionForm';
import { AddonsFormValues } from 'context/formContext/addonsForm';
import { AboutFormValues } from 'context/formContext/aboutForm';
import { OrderFormValues } from 'context/formContext/orderForm';
import { UseFormReturn } from 'react-hook-form';
interface Steps {
  label: string;
  form:
    | UseFormReturn<SubscriptionFormValues>
    | UseFormReturn<AddonsFormValues>
    | UseFormReturn<AboutFormValues>
    | UseFormReturn<OrderFormValues>;
}
interface ProgressBarProps {
  steps: Steps[];
  currentStep: number;
  onStepChange: (step: Pages) => void;
}

// TODO: When problem with lack of access to taxdoo-ui theme gets fixed replace colors with appropriate color names from it.
export const ProgressBar: FC<ProgressBarProps> = ({
  steps,
  currentStep,
  onStepChange,
}) => {
  const [lastCompletedStep, setLastCompletedStep] = useState(currentStep);

  const onStepClick = (step: Pages) => {
    if (step > lastCompletedStep) {
      return;
    }

    onStepChange(step);
  };

  useEffect(() => {
    if (currentStep > lastCompletedStep) {
      setLastCompletedStep(currentStep);
    }
  }, [currentStep, lastCompletedStep]);

  return (
    <Box width={'full'}>
      <Flex>
        {steps.map(({ label }, index) => {
          const isActive = currentStep === index;
          const wasCompleted = index < lastCompletedStep;
          const isLastCompleted = index === lastCompletedStep;

          const onClick = () => {
            onStepClick(index as Pages);
          };

          return (
            <Flex width={'full'} direction={'column'} alignItems={'center'} key={label}>
              <Box
                background={'grey.300'}
                mb={'22px'}
                height={'4px'}
                width={'100%'}
                position={'relative'}>
                <Box
                  background={'taxdooBlue.900'}
                  width={
                    wasCompleted ? '100%' : isLastCompleted ? '50%' : isActive ? '50%' : 0
                  }
                  height={'4px'}
                  top={0}
                  left={0}
                  position={'absolute'}
                />
                <Box
                  onClick={onClick}
                  cursor={wasCompleted || isLastCompleted ? 'pointer' : 'default'}
                  position={'absolute'}
                  boxSize={'12px'}
                  background={
                    isActive
                      ? '#FFFFFF'
                      : wasCompleted
                      ? '#1E3C69'
                      : isLastCompleted
                      ? '#1E3C69'
                      : '#E1E1E1'
                  }
                  borderRadius={'50%'}
                  outline={`6px solid ${isActive ? '#1E3C69' : 'transparent'}`}
                  top={'50%'}
                  left={'50%'}
                  transform={'translate(-50%, -50%)'}
                />
              </Box>
              <Box
                display={{ base: isActive ? 'block' : 'none', md: 'block' }}
                onClick={onClick}>
                <Text
                  textAlign={'center'}
                  cursor={wasCompleted || isLastCompleted ? 'pointer' : 'default'}
                  color={wasCompleted || isLastCompleted ? '#1E3C69' : '#737373'}
                  opacity={
                    (wasCompleted || isLastCompleted) && !isActive ? '50%' : undefined
                  }
                  fontSize={{ base: '16px', md: '18px' }}
                  fontWeight={isActive ? 'bold' : 'semibold'}
                  _hover={
                    (wasCompleted || isLastCompleted) && !isActive
                      ? {
                          color: '#006EAA',
                        }
                      : undefined
                  }>
                  {`${index + 1}. ${label}`}
                </Text>
              </Box>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};
