import { FlagIconProps } from '@types';

export const FlagES = ({
  width = '26',
  height = '18',
  active = false,
  ...props
}: FlagIconProps) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={active ? '1' : '0.3'}>
        <path d="M25 1H1V17H25V1Z" fill="#C60B1E" />
        <path d="M25 5H1V13H25V5Z" fill="#FFC400" />
        <rect x="0.5" y="0.5" width="25" height="17" rx="1.5" stroke="#E1E1E1" />
      </g>
    </svg>
  );
};
