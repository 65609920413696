import { v4 } from 'uuid';

interface Product {
  name: string;
  id: string;
  price: string;
  brand: string;
  quantity: number;
}

export enum FormStep {
  Subscription = 1,
  Addons = 2,
  About = 3,
  Summary = 4,
}

class DataLayer {
  public products: Product[] = [];
  public id = v4();

  public add(name: string, id: string, price: string) {
    const product: Product = {
      name,
      id,
      price,
      brand: 'Taxdoo',
      quantity: 1,
    };

    window?.dataLayer?.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: [product],
        },
      },
    });
    this.products.push(product);
  }

  public remove(id: string) {
    const productToRemove = this.products.find((product) => product.id === id);

    if (!productToRemove) {
      return;
    }

    window?.dataLayer?.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'EUR',
        remove: {
          products: [productToRemove],
        },
      },
    });
    this.products = this.products.filter((product) => product.id !== id);
  }

  public checkout(step: number) {
    window?.dataLayer?.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step },
          products: this.products,
        },
      },
    });
  }

  public purchase(revenue: string, tax: string) {
    window?.dataLayer?.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: this.id,
            affiliation: 'Taxdoo Booking',
            revenue,
            tax,
          },
          products: this.products,
        },
      },
    });
  }
}

const dataLayer = new DataLayer();

export default dataLayer;
