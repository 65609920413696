import { FlagIconProps } from '@types';

export const FlagPL = ({
  width = '26',
  height = '18',
  active = false,
  ...props
}: FlagIconProps) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={active ? '1' : '0.3'}>
        <g clipPath="url(#clip0_1476_11879)">
          <path d="M25.8 1H0.200012V17H25.8V1Z" fill="white" />
          <path d="M25.8 9H0.200012V17H25.8V9Z" fill="#DC143C" />
        </g>
        <rect x="0.5" y="0.5" width="25" height="17" rx="1.5" stroke="#E1E1E1" />
      </g>
      <defs>
        <clipPath id="clip0_1476_11879">
          <rect x="1" y="1" width="24" height="16" rx="1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
