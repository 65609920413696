import { FlagIconProps } from '@types';

export const FlagSE = ({
  width = '26',
  height = '18',
  active = false,
  ...props
}: FlagIconProps) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={active ? '1' : '0.3'}>
        <g clipPath="url(#clip0_1476_11916)">
          <path d="M0.200012 1H25.8V17H0.200012V1Z" fill="#006AA7" />
          <path
            d="M0.200012 7.4H8.20001V1H11.4V7.4H25.8V10.6H11.4V17H8.20001V10.6H0.200012V7.4Z"
            fill="#FECC00"
          />
        </g>
        <rect x="0.5" y="0.5" width="25" height="17" rx="1.5" stroke="#E1E1E1" />
      </g>
      <defs>
        <clipPath id="clip0_1476_11916">
          <rect x="1" y="1" width="24" height="16" rx="1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
