import { FC, useMemo } from 'react';
import { Flex, Text, Checkbox, Tooltip, CheckboxProps } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export interface ExportAddOnCheckboxProps {
  title: string;
  desc: string; // desc = description
  tooltip?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  value: string;
  onChange: (val: boolean) => void;
}

export const ExportAddOnCheckbox: FC<ExportAddOnCheckboxProps> = ({
  title,
  desc,
  tooltip,
  value,
  isDisabled = false,
  isChecked,
  onChange,
}) => {
  const checkboxProps = useMemo<CheckboxProps>(() => {
    const props: CheckboxProps = {};

    if (isDisabled) {
      props.borderWidth = '2px';
      props.borderStyle = 'solid';
      props.borderColor = 'grey.400';
      props.size = 'sm';
      props.borderRadius = '2px';
    }

    return props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, isChecked]);

  return (
    <Flex
      gap={'20px'}
      p={'20px'}
      onClick={(event) => {
        event.preventDefault();
        if (isDisabled) return;

        const newState = !isChecked;
        isChecked = newState;
        onChange(newState);
      }}
      boxShadow={isDisabled || isChecked ? 'none' : 'md'}
      _hover={{
        boxShadow: isDisabled || isChecked ? 'none' : 'lg',
      }}
      bg={isDisabled ? '#F5F5F5' : isChecked ? '#EBF5FF' : 'white'}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      rounded={'md'}
      alignItems={'flex-start'}
      transition={'all 0.2s ease-in-out'}
      w={'100%'}>
      <Checkbox
        data-testid={`export-addon-${value}-checkbox`}
        isDisabled={isDisabled}
        isChecked={isChecked}
        mt="2"
        {...checkboxProps}
      />
      <Flex direction={'column'} justifyContent={'flex-start'} gap={'4px'}>
        <Text
          fontSize={'xl'}
          fontWeight={'bold'}
          color={isDisabled ? '#5F5F5F' : isChecked ? 'taxdooBlue.900' : 'black'}>
          {title}
          {tooltip && (
            <span>
              <Tooltip
                label={tooltip}
                hasArrow
                bg="info.600"
                placement="top"
                shouldWrapChildren>
                <InfoOutlineIcon
                  ml={'10px'}
                  mb={'2px'}
                  w={'20px'}
                  h={'20px'}
                  color="#737373"
                />
              </Tooltip>
            </span>
          )}
        </Text>
        <Text
          color={isDisabled ? '#5F5F5F' : isChecked ? 'taxdooBlue.900' : 'black'}
          fontSize={'lg'}>
          {desc}
        </Text>
      </Flex>
    </Flex>
  );
};
