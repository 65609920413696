import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Flex, Divider, Text, Tooltip, Box } from '@chakra-ui/react';
import { BasePackage, LanguageCode, LanguageFormatLocale } from '@types';
import { useBookingForm } from 'context/formContext/formContext';
import { usePrice } from 'context/priceContext/priceContext';
import { useTranslation } from 'react-i18next';
import i18n from 'services/i18n';
import { Addons, AddonsNames } from 'utils/constants/packageGmvMap';
import { formatNumber } from 'utils/helpers';

type Props = {
  /**
   * Wether or not to show the total price at the bottom
   */
  showTotal?: boolean;
  /**
   * if set to true the component will render a slightly compact version (for StickyFooter)
   */
  isCompact?: boolean;
};

interface AddOn {
  name: string;
  price: number;
}

/**
 * Render a component containing purchase summary details
 */
export const PurchaseSummary = ({ showTotal = false, isCompact = false }: Props) => {
  const { t } = useTranslation(['order', 'addons', 'about', 'others', 'footer']);
  const exportAddons = t('addons:exportAddons', {
    returnObjects: true,
  });
  const { subscribeForm, addonsForm } = useBookingForm();
  const { prices, total, addonsTotalPrice } = usePrice();
  const selectedLanguage = LanguageFormatLocale[i18n.language as LanguageCode];

  const selectedPackage = subscribeForm.watch('package');
  const sales = subscribeForm.watch('sales');
  // const filings = addonsForm.watch('filings');
  const {
    filings,
    registrations,
    oss: hasOss,
    datev: hasDateve,
    datevPayments: hasDatevPayments,
    invoice: hasInvoice,
  } = addonsForm.watch();

  /**
   * Get the translated addon name
   * @param value addon unique name
   * @returns addon name if found or an error text
   */
  const getAddonName = (value: AddonsNames): string => {
    const addonName = exportAddons.addons.find((addon) => addon.value === value);
    return addonName?.header || t('order:purchase.addOns.nameNotFound');
  };
  /**
   * Generates a list of all booked addons
   * @returns list of addons objects
   */
  const getAddonsList = (): AddOn[] => {
    const addonsList: AddOn[] = [];
    hasOss && addonsList.push({ name: getAddonName(Addons.oss), price: prices.oss });
    hasDateve &&
      addonsList.push({ name: getAddonName(Addons.datev), price: prices.datev });
    hasDatevPayments &&
      addonsList.push({
        name: getAddonName(Addons.datevPayments),
        price: prices.datevPayments,
      });
    hasInvoice &&
      addonsList.push({ name: getAddonName(Addons.invoice), price: prices.invoice });

    // Number of selected filings (one per selected countries)
    const filingCount = filings?.length || 0;

    // Number of selected registrations (one per selected countries)
    const registrationCount = registrations?.length || 0;

    const filingsAddons: AddOn[] = [];
    const regAddons: AddOn[] = [];

    if (filingCount) {
      filingsAddons.push({
        name: t('order:purchase.addOns.filingDescription', {
          count: filingCount,
        }),
        price: 99 * filingCount,
      });
    }

    if (registrationCount) {
      regAddons.push({
        name: t('order:purchase.addOns.regDescription', {
          count: registrationCount,
        }),
        price: 0,
      });
    }

    return [...addonsList, ...filingsAddons, ...regAddons];
  };

  const subscriptionPlans = {
    [BasePackage.Premium]: t('order:purchase.package.planNames.premium'),
    [BasePackage.Professional]: t('order:purchase.package.planNames.professional'),
  };
  const packageDescription =
    sales && selectedPackage
      ? t('order:purchase.package.description', {
          package: subscriptionPlans[selectedPackage as BasePackage],
          sales: sales,
        })
      : '';

  return (
    <>
      <Flex
        direction={'column'}
        maxH={'70vh'}
        w={{
          base: '100%',
          md: isCompact ? '35%' : '100%',
        }}
        gap="4">
        <Flex justifyContent={'space-between'}>
          <Flex direction={'column'} gap={'1'} maxW={'70%'}>
            <Text fontSize={'xl'} fontWeight={'semibold'}>
              {t('order:purchase.package.heading')}
            </Text>
            <Text fontSize={isCompact ? 'md' : 'lg'}>
              {packageDescription}
              {!isCompact && <sup>1</sup>}
            </Text>
          </Flex>
          <Text data-testid="purchase-package-total-price" fontWeight={'bold'}>
            {formatNumber(prices.package || 0, selectedLanguage)} €
          </Text>
        </Flex>
        <Divider borderColor={'#BEBEBE'} borderBottomWidth={'2px'} />
        {!!getAddonsList().length && (
          <>
            <Flex justifyContent={'space-between'} my={'24px'}>
              <Text fontSize={'xl'} fontWeight={'semibold'}>
                {t('order:purchase.addOns.heading')}
              </Text>
              <Text
                data-testid="purchase-addons-total-price"
                fontSize={'xl'}
                fontWeight={'semibold'}
                w={'10ch'}
                textAlign={'right'}>
                {formatNumber(addonsTotalPrice, selectedLanguage)} €
              </Text>
            </Flex>
            {/* Addons */}
            {getAddonsList().map((addOn, index) => {
              return (
                <Flex
                  key={addOn.name}
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  <Text fontSize={'lg'}>{addOn.name}</Text>
                  <Flex gap={'28px'} alignItems={'center'} ml="10px">
                    <Text
                      data-testid={`purchase-addons-${index + 1}-price`}
                      whiteSpace={'nowrap'}
                      fontSize={'lg'}
                      textAlign={'center'}>
                      {formatNumber(addOn.price, selectedLanguage)} €
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
          </>
        )}
        {showTotal && (
          <Flex justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>{t('order:summary.total')}</Text>
            <Box display={'flex'} flexDirection={'column'} alignItems={'end'}>
              <Text data-testid="package-total-price" fontWeight={'bold'}>
                {formatNumber(total || 0, selectedLanguage)} €
              </Text>
              <Box
                display={['flex', 'none']}
                flexDirection={'column'}
                fontSize={'md'}
                alignItems={'end'}>
                {selectedPackage &&
                  (selectedPackage === BasePackage.Premium ? (
                    <>
                      <Text>{t('footer:summary.perMonth')}</Text>
                      <Text>{t('footer:summary.yearly')}</Text>
                    </>
                  ) : (
                    <>
                      <Text>
                        {t('footer:summary.perMonth')}{' '}
                        <Tooltip
                          label={t('footer:sticky.pricingChangeWarning')}
                          hasArrow
                          placement="top"
                          bg="info.600"
                          shouldWrapChildren>
                          <InfoOutlineIcon ml={'5px'} mb={'2px'} />
                        </Tooltip>
                      </Text>
                      <Text>{t('footer:summary.monthly')}</Text>
                    </>
                  ))}
              </Box>
            </Box>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default PurchaseSummary;
