import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  subscriptionFormDefaults,
  subscriptionFormSchema,
  SubscriptionFormValues,
} from './subscriptionForm';
import { addonsFormDefaults, addonsFormSchema, AddonsFormValues } from './addonsForm';
import { aboutFormDefaults, aboutFormSchema, AboutFormValues } from './aboutForm';
import { orderFormDefaults, orderFormSchema, OrderFormValues } from './orderForm';

interface BookingFormContextValues {
  subscribeForm: UseFormReturn<SubscriptionFormValues>;
  addonsForm: UseFormReturn<AddonsFormValues>;
  aboutForm: UseFormReturn<AboutFormValues>;
  orderForm: UseFormReturn<OrderFormValues>;
}

const BookingFormContext = createContext<BookingFormContextValues>(
  {} as BookingFormContextValues,
);

export const BookingFormProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  const forms = {
    subscribeForm: useForm<SubscriptionFormValues>({
      defaultValues: subscriptionFormDefaults,
      resolver: yupResolver(subscriptionFormSchema(t)),
      mode: 'onChange',
    }),
    addonsForm: useForm<AddonsFormValues>({
      defaultValues: addonsFormDefaults,
      resolver: yupResolver(addonsFormSchema()),
      mode: 'onChange',
    }),
    aboutForm: useForm<AboutFormValues>({
      defaultValues: aboutFormDefaults,
      resolver: yupResolver(aboutFormSchema(t)),
      mode: 'onChange',
    }),
    orderForm: useForm<OrderFormValues>({
      defaultValues: orderFormDefaults,
      resolver: yupResolver(orderFormSchema),
      mode: 'onChange',
    }),
  };

  return (
    <BookingFormContext.Provider value={forms}>{children}</BookingFormContext.Provider>
  );
};

export const useBookingForm = () => useContext(BookingFormContext);
