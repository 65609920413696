import 'react-datepicker/dist/react-datepicker.css';

import { Input, InputProps } from '@chakra-ui/react';

import { Controller, useFormContext } from 'react-hook-form';

import { FieldProps } from '@types';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import FormField from './FormField';

type Props = FieldProps &
  InputProps & { name: string } & Omit<ReactDatePickerProps, 'onChange'>;

/**
 * Renders a custom date picker component with label and error message support
 */
export const DatePickerField = ({
  id,
  name,
  placeholder,
  dateFormat = 'dd.MM.yyyy',
  ...props
}: Props) => {
  const { control } = useFormContext();
  return (
    <FormField {...{ id, ...props }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const selectedDate = field.value ? new Date(field.value) : null;
          return (
            <ReactDatePicker
              {...props}
              dateFormat={dateFormat}
              placeholderText={placeholder}
              selected={selectedDate}
              onChange={(date: Date) => date && field.onChange(date)}
              customInput={<Input size="lg" id={id} fontSize={'xl'} />}
            />
          );
        }}
      />
    </FormField>
  );
};

export default DatePickerField;
