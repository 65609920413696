import { FlagIconProps } from '@types';

export const FlagBE = ({
  width = '26',
  height = '18',
  active = false,
  ...props
}: FlagIconProps) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={active ? '1' : '0.3'}>
        <g clipPath="url(#clip0_1476_11742)">
          <path d="M1 -1.40039H25V19.3996H1V-1.40039Z" fill="#EF3340" />
          <path d="M1 -1.40039H17V19.3996H1V-1.40039Z" fill="#FDDA25" />
          <path d="M1 -1.40039H9V19.3996H1V-1.40039Z" fill="black" />
        </g>
        <rect x="0.5" y="0.5" width="25" height="17" rx="1.5" stroke="#E1E1E1" />
      </g>
      <defs>
        <clipPath id="clip0_1476_11742">
          <rect x="1" y="1" width="24" height="16" rx="1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
