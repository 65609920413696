import { SelectProps } from '@chakra-ui/react';
import { FieldProps } from '@types';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from './SelectField';

type Props = SelectProps & FieldProps;

/**
 * A wrapper around the SelectField that passes the EU countries as options.
 */
export const CountrySelect = forwardRef<HTMLSelectElement, Props>(({ ...props }, ref) => {
  const { t } = useTranslation(['others']);
  const euCountries = t('others:countries', {
    returnObjects: true,
  });

  const topCountriesArray = [
    { label: euCountries.DE, value: 'DE' },
    { label: euCountries.AT, value: 'AT' },
    { label: euCountries.FR, value: 'FR' },
  ];
  const TOP_COUNTRIES = topCountriesArray.map((el) => el.value);

  const countryOptions = Object.entries(euCountries)
    .map(([value, label]) => ({
      label,
      value,
    }))
    .filter((el) => !TOP_COUNTRIES.includes(el.value))
    .sort();

  countryOptions.unshift(...topCountriesArray);
  return <SelectField options={countryOptions} {...{ ref, ...props }} />;
});

CountrySelect.displayName = 'CountrySelect';
export default CountrySelect;
